
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import BaseGridLayout from '../layouts/BaseGridLayout.vue'
import IContact from '../../shared/general/interfaces/IContact'
import IContactElement from '../../shared/general/interfaces/IContactElement'

@Component({
  name: 'ContactElement',
  components: {
    BaseGridLayout,
    BaseHeadline: () => import('../base/BaseHeadline.vue'),
    ContactElements: () => import('./ContactElements.vue'),
  },
})
export default class ContactElement extends Vue {
  @Prop() headline ?: string

  @Prop({ default: 'standard' }) size! : 'small' | 'standard'

  @Prop({ default: () => [] }) information! : IContact[]

  styles = {
    wrapper: {
      small: 'md:grid-cols-3 sm:grid-cols-2 grid-cols-1 items-stretch',
    },
    teaser: {
      small: 'content-start pt-6',
      standard: 'grid-cols-1 md:grid-cols-3 pt-6',
    },
    columns: {
      standard: 'sm:w-1/2 md:w-full',
      colMid: {
        standard: 'md:pb-6',
      },
      colRight: {
        standard: 'md:pb-6 md:pt-0',
      },
    },
  }

  private isFirstAndOnlyElementImage (content : IContactElement[]) : string | boolean {
    return content[0] && content[0].type === 'image' && content.length === 1
      && this.size === 'standard' && 'md:pb-0'
  }
}
